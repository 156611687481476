import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from "gantt-task-react";
import "gantt-task-react/dist/index.css";

import "./styles.css";

let tasks: Task[] = [
  {
    start: new Date(2023, 1, 20),
    end: new Date(2023, 1, 26),
    name: "制作《关于学习》的 PPT",
    id: "ppt",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 1, 26),
    end: new Date(2023, 1, 27),
    name: "录制音频视频版本",
    id: "video",
    dependencies: ["ppt"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 2, 5),
    end: new Date(2023, 2, 8),
    name: "撰写文本版本",
    id: "text",
    dependencies: ["video"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 2, 13),
    end: new Date(2023, 3, 10),
    name: "文章投稿少数派",
    id: "sspai",
    dependencies: ["text"],
    type: "milestone",
    progress: 100,
  },
  {
    start: new Date(2023, 3, 3),
    end: new Date(2023, 3, 10),
    name: "阉割文章以符合少数派审稿标准",
    id: "kill-article",
    dependencies: ["sspai"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 3, 14),
    end: new Date(2023, 3, 15),
    name: "发起文集撰写项目",
    id: "writing-project",
    type: "milestone",
    progress: 100,
  },
  {
    start: new Date(2023, 3, 19),
    end: new Date(2023, 3, 20),
    name: "撰写内容规划",
    id: "draft-blueprint",
    dependencies: ["writing-project"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 3, 26),
    end: new Date(2023, 3, 26),
    name: "撰写核心故事线",
    id: "draft-core",
    dependencies: ["writing-project"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 4, 2),
    end: new Date(2023, 4, 7),
    name: "撰写内容提纲",
    id: "draft-blueprint",
    dependencies: ["writing-project"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 4, 10),
    end: new Date(2023, 4, 10),
    name: "开始撰写初稿",
    id: "initial-ver",
    type: "milestone",
    progress: 100,
  },
  {
    start: new Date(2023, 4, 10),
    end: new Date(2023, 4, 24),
    name: "撰写「为什么我不想学习」",
    id: "w-hate-learning",
    dependencies: ["initial-ver"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 4, 28),
    end: new Date(2023, 5, 8),
    name: "撰写「我为什么怎么样都学不会」",
    id: "w-cant-learn",
    dependencies: ["initial-ver"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 4, 12),
    end: new Date(2023, 4, 16),
    name: "撰写「我为什么我没有办法面对自己」",
    id: "w-hate-myself",
    dependencies: ["initial-ver"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 4, 17),
    end: new Date(2023, 4, 28),
    name: "撰写「为什么我没有办法逃离情绪」",
    id: "w-emotion",
    dependencies: ["initial-ver"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 5, 23),
    end: new Date(2023, 6, 19),
    name: "撰写「为什么我面对重大抉择的时候会感到迷茫」",
    id: "w-value",
    dependencies: ["initial-ver"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 6, 19),
    end: new Date(2023, 6, 30),
    name: "撰写「为什么我的作文会这么空洞？」",
    id: "w-essay",
    dependencies: ["initial-ver"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 7, 10),
    end: new Date(2023, 7, 17),
    name: "撰写「我为什么完全没有办法专注？」",
    id: "w-essay",
    dependencies: ["initial-ver"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 7, 19),
    end: new Date(2023, 7, 28),
    name: "撰写后记",
    id: "w-essay",
    dependencies: ["initial-ver"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 7, 24),
    end: new Date(2023, 8, 2),
    name: "撰写序言",
    id: "w-essay",
    dependencies: ["initial-ver"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 8, 8),
    end: new Date(2023, 8, 8),
    name: "建立试读群",
    id: "trail-group",
    type: "milestone",
    progress: 100,
  },
  {
    start: new Date(2023, 8, 8),
    end: new Date(2023, 8, 18),
    name: "初稿审校",
    id: "w-review-1",
    dependencies: ["trail-group"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 8, 11),
    end: new Date(2023, 8, 11),
    name: "手工装订图书试印",
    id: "test-print",
    type: "milestone",
    progress: 100,
  },
  {
    start: new Date(2023, 8, 11),
    end: new Date(2023, 8, 12),
    name: "绘制章节题头图",
    id: "w-sec-pic",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 8, 18),
    end: new Date(2023, 9, 4),
    name: "二稿审校",
    id: "w-review-2",
    dependencies: ["w-review-1"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 8, 20),
    end: new Date(2023, 8, 24),
    name: "第一次小规模试印",
    id: "print-small",
    dependencies: ["test-print"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 8, 23),
    end: new Date(2023, 10, 30),
    name: "台版精排版初稿",
    id: "typ-1",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 8, 18),
    end: new Date(2023, 8, 25),
    name: "封面绘制",
    id: "build-cover",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 9, 6),
    end: new Date(2023, 9, 11),
    name: "第一次众测试印",
    id: "print-1",
    type: "task",
    dependencies: ["print-small"],
    progress: 100,
  },
  {
    start: new Date(2023, 9, 8),
    end: new Date(2023, 9, 9),
    name: "插图重绘",
    id: "repaint",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 9, 10),
    end: new Date(2023, 9, 10),
    name: "机器校对",
    id: "machine-review",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 9, 10),
    end: new Date(2023, 9, 10),
    name: "简中版本定稿",
    id: "version",
    type: "milestone",
    progress: 100,
  },
  {
    start: new Date(2023, 9, 13),
    end: new Date(2023, 9, 13),
    name: "封面定稿",
    id: "cover",
    dependencies: ["build-cover"],
    type: "milestone",
    progress: 100,
  },
  {
    start: new Date(2023, 9, 12),
    end: new Date(2023, 9, 26),
    name: "简体中文版正式校对",
    id: "paid-review-1",
    dependencies: ["machine-review"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 9, 12),
    end: new Date(2023, 9, 23),
    name: "官网设计制作",
    id: "build-site",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 9, 30),
    end: new Date(2023, 10, 1),
    name: "台版本地化",
    id: "tw-loc",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 10, 1),
    end: new Date(2023, 10, 2),
    name: "录制音频版：前言",
    id: "p-ep0",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 10, 2),
    end: new Date(2023, 10, 2),
    name: "录制音频版： 第一章",
    id: "p-ep1",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 10, 2),
    end: new Date(2023, 10, 3),
    name: "录制音频版： 第二章",
    id: "p-ep2",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 10, 4),
    end: new Date(2023, 10, 5),
    name: "录制音频版： 第三章",
    id: "p-ep3",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 10, 5),
    end: new Date(2023, 10, 5),
    name: "录制音频版： 第四章",
    id: "p-ep4",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 10, 5),
    end: new Date(2023, 10, 6),
    name: "录制音频版： 第五章",
    id: "p-ep5",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 10, 6),
    end: new Date(2023, 10, 6),
    name: "录制音频版： 第六章",
    id: "p-ep6",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 10, 6),
    end: new Date(2023, 10, 7),
    name: "录制音频版： 第七章",
    id: "p-ep7",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 10, 7),
    end: new Date(2023, 10, 8),
    name: "录制音频版： 第八章",
    id: "p-ep8",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 10, 2),
    end: new Date(2023, 10, 17),
    name: "台版正式校对",
    id: "paid-review-2",
    dependencies: ["tw-loc"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 11, 3),
    end: new Date(2023, 11, 6),
    name: "台版第一次试印",
    id: "tw-print-1",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 11, 4),
    end: new Date(2023, 11, 4),
    name: "开始申请台版 ISBN",
    id: "pub",
    type: "milestone",
    progress: 100,
  },
  {
    start: new Date(2023, 11, 5),
    end: new Date(2023, 11, 8),
    name: "刻制出版社公章",
    id: "pub-stamp-1",
    dependencies: ["pub"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 11, 6),
    end: new Date(2023, 11, 10),
    name: "编发 ISBN",
    id: "isbn",
    dependencies: ["pub"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 11, 10),
    end: new Date(2023, 11, 15),
    name: "编发 CIP",
    id: "cip",
    dependencies: ["isbn"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 11, 18),
    end: new Date(2023, 11, 19),
    name: "录制音频版：终章",
    id: "p-ep9",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 11, 18),
    end: new Date(2023, 11, 18),
    name: "录制音频版：第六章 - 重录",
    id: "p-ep6-r",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 11, 18),
    end: new Date(2023, 11, 19),
    name: "录制音频版：第五章 - 重录",
    id: "p-ep5-r",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 11, 18),
    end: new Date(2023, 11, 19),
    name: "录制音频版：第七章 - 重录",
    id: "p-ep7-r",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 11, 19),
    end: new Date(2023, 11, 19),
    name: "录制音频版：第八章 - 重录",
    id: "p-ep7-r",
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 10, 30),
    end: new Date(2023, 11, 24),
    name: "台版精排版二稿",
    id: "typ-2",
    type: "task",
    dependencies: ["typ-1"],
    progress: 100,
  },
  {
    start: new Date(2023, 11, 15),
    end: new Date(2023, 11, 19),
    name: "台版最后一次编辑",
    id: "tw-final-edit",
    type: "task",
    dependencies: ["paid-review-2"],
    progress: 100,
  },
  {
    start: new Date(2023, 11, 15),
    end: new Date(2023, 11, 15),
    name: "简体教材编写工作计划",
    id: "course",
    type: "milestone",
    progress: 100,
  },
  {
    start: new Date(2023, 11, 16),
    end: new Date(2023, 11, 16),
    name: "撰写书籍内容简介",
    id: "book-intro",
    dependencies: ["course"],
    type: "task",
    progress: 100,
  },
  {
    start: new Date(2023, 11, 24),
    end: new Date(2023, 11, 24),
    name: "台版定稿",
    id: "tw-final-edit",
    type: "milestone",
    dependencies: ["tw-final-edit", "typ-2", "cip"],
    progress: 100,
  },
  {
    start: new Date(2023, 11, 5),
    end: new Date(2023, 11, 26),
    name: "台版 EPUB 制作",
    id: "epub",
    type: "task",
    progress: 100,
  },
];

export const App = () => <Gantt tasks={tasks} listCellWidth="" locale="chi" />;
